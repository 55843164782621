import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getPromotionPRCs } from 'productSelection/services/promotions';
import { useTypedSelector, type AppState } from 'shared/store';
import { DEFAULT_LOCALE } from 'tools/locales';

import { useGetPromotions } from './useGetPromotions';

interface PromotionsConfig {
  forSkuCode?: string;
  locale?: string;
  marketReference: string;
}

export const usePromotions = (
  config?: PromotionsConfig,
): AppState['promotions'] => {
  const { forSkuCode, locale, marketReference } = config || {
    locale: DEFAULT_LOCALE,
  };
  const getPromotions = useGetPromotions();
  const dispatch = useDispatch();
  const promotions = useTypedSelector((state) => state.promotions);
  const skuPromotion = promotions.details[forSkuCode];

  // fetch promotions
  useEffect(() => {
    const parsedCoupons = getPromotionPRCs();
    const shouldLoadPromotions = parsedCoupons.length && !skuPromotion;
    if (shouldLoadPromotions) {
      void getPromotions(parsedCoupons);
    }
    // fetching promotions depends on the locale so anytime it changes we should execute again
  }, [
    dispatch,
    forSkuCode,
    getPromotions,
    locale,
    marketReference,
    skuPromotion,
  ]);

  return promotions;
};
