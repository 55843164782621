import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  getPromotionsByCouponCode,
  type Promotion,
} from 'shared/infra/commerceLayer/promotions';
import {
  loadPromotionsFailure,
  loadPromotionsSuccess,
} from 'shared/store/promotions/actions';

type UseGetPromotionsResult = (
  coupons: string[],
) => Promise<Record<string, Promotion>>;

export const useGetPromotions = (): UseGetPromotionsResult => {
  const dispatch = useDispatch();

  const getPromotions = useCallback(
    async (
      coupons: string[],
    ): Promise<Record<string, Promotion> | undefined> => {
      try {
        const promotionsBySku = await getPromotionsByCouponCode(coupons);
        dispatch(loadPromotionsSuccess(promotionsBySku));

        return promotionsBySku;
      } catch (error) {
        dispatch(loadPromotionsFailure('Error loading promotions.'));
      }

      return undefined;
    },
    [dispatch],
  );

  return getPromotions;
};
